import moment from 'moment'
import {
  PLANTYPES
} from '@/constants/interface'

const install = (Vue) => {
  Vue.filter('planTypes', (value) => {
    const values = `${value}`.split(',')
    const weekdays = values.map(item => PLANTYPES[item] || '未知')
    return weekdays.join('/')
  })

  Vue.filter('formatDate', (value, type = 'YYYY-MM-DD HH:mm:ss') => {
    return moment(value).format(type)
  })

  Vue.filter('formatKeyType', (value) => {
    if (value == 1) {
      return '展示字段'
    } else if (value == 2) {
      return '计算字段'
    }
  })

}

export default { install }
