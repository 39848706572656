import {
    PLANTYPES
} from '@/constants/interface'

export const formatPlanTypes = (value) => {
    const values = `${value}`.split(',')
    const weekdays = values.map(item => PLANTYPES[item] || '未知')
    return weekdays.join('/')
}

export const log = (msg, type = 'log', styleStr) => {
    let style = styleStr || [
        'padding: 10px 40px',
        'font-size: 24px',
        'font-weight: 600',
        'color:#003A7A',
        'border-radius: 10px',
        'background-color:#FF6C35',
        'background-size: 100px 50px',
        `background-image:
        radial-gradient(circle at 100% 150%, #FF6C35 24%, white 24%, white 28%, #FF6C35 28%, #FF6C35 36%, white 36%, white 40%, transparent 40%, transparent),
        radial-gradient(circle at 0 150%, #FF6C35 24%, white 24%, white 28%, #FF6C35 28%, #FF6C35 36%, white 36%, white 40%, transparent 40%, transparent),
        radial-gradient(circle at 50% 100%, white 10%, #FF6C35 10%, #FF6C35 23%, white 23%, white 30%, #FF6C35 30%, #FF6C35 43%, white 43%, white 50%, #FF6C35 50%, #FF6C35 63%, white 63%, white 71%, transparent 71%, transparent),
        radial-gradient(circle at 100% 50%, white 5%, #FF6C35 5%, #FF6C35 15%, white 15%, white 20%, #FF6C35 20%, #FF6C35 29%, white 29%, white 34%, #FF6C35 34%, #FF6C35 44%, white 44%, white 49%, transparent 49%, transparent),
        radial-gradient(circle at 0 50%, white 5%, #FF6C35 5%, #FF6C35 15%, white 15%, white 20%, #FF6C35 20%, #FF6C35 29%, white 29%, white 34%, #FF6C35 34%, #FF6C35 44%, white 44%, white 49%, transparent 49%, transparent);
      `
    ].join(';')
    console[type](`%c${msg}`, style)
}


