import { httpGet, httpPostFormData, httpDeleate_v2, httpPostRequstBody, httpGetCancelable } from './httpUtils'
import { toFormData } from './form-data-util'

// 查询过滤订单(或预警订单)
export function ordersFilterCancelAble(path, params, source) {
    const {
        page,
        size,
        statuses,
        col,
        cod,
        user_company_name,
        transport_type,
        remarkText,
        statement_company_ids,
        pol,
        pod,
        po_no,
        bill_no,
        container_no,
        expose_price,
        remark,
        sold_by_id,
    } = params;
    return httpGetCancelable(path, {
        page,
        size,
        statuses,
        col,
        cod,
        user_company_name,
        transport_type,
        remarkText,
        statement_company_ids,
        pol,
        pod,
        po_no,
        bill_no,
        container_no,
        expose_price,
        remark,
        sold_by_id,
    }, source);
}

// 创建平帐 账单
export function flatBill(data) {
    const { orderId, id, remark, simplify } = data
    console.log(orderId, id, remark, simplify, data, "orderId,id,remark,simplify", "data")

    return httpPostRequstBody(`/admin/statement/resolve/last_customer_statement/${id}`, {
        id,
        remark: remark || '',
        simplify: simplify || ''
    })
}

// 根据订单id获取订单信息
export function getOrderById(orderId) {
    return httpGet(`/order/${orderId}`)
}

// 获取用户的referer
export function getRrferer(userId) {
    return httpGet(`/user/admin/referer/${userId}`)
}

// 获取订单下账单数量
export function getStatementsNumberByOrder(orderId) {
    return httpGet(`/admin/statements/size/by/order/${orderId}`)
}

// 根据订单编号获取账单列表
export function getStatementsByOrder(orderId) {
    return httpGet(`/admin/statements/content/by/order/${orderId}`)
}

// 更新账单appendix备注
export function updateAppendix(params) {
    let {
        id,
        appendix,
        lang,
        simplify,
    } = params;
    simplify = simplify || true;
    return httpPostFormData(`/admin/statement/${id}/update_appendix`, toFormData({
        lang,
        appendix,
        simplify,
    }))
}

// 新建账单
export function addStatements(params) {
    let {
        orderId,
        companyAccountId,
        role,
        type,
        displayNo,
        accountingProducts,
        remark,
        shouldPayAt,
        simplify,
        createdAt,
        appendix_zh,
        appendix_en,
    } = params
    return httpPostFormData('/admin/statement/create', toFormData({
        order_id: orderId,
        company_account_id: companyAccountId,
        role: role,
        type: type,
        display_no: displayNo,
        accounting_products: accountingProducts,
        remark: remark,
        should_pay_at: shouldPayAt,
        simplify: simplify,
        created_at: createdAt,
        appendix_zh,
        appendix_en,
    }))
}

// created账单
export function createdStatements(params) {
    let {
        id,
        remark,
        simplify = simplify || "",
    } = params
    console.log(params, 'params')
    return httpPostFormData('/admin/statement/credit/' + id, toFormData({
        id,
        remark,
        simplify,
    }))
}

//批量修改确认账单
export function updateAccountCofirm(params) {
    let {
        orderId,
        companyId,
        done,
        role,
        simplify
    } = params
    return httpPostFormData('/admin/statements/update_bill_confirm', toFormData({
        order_id: orderId,
        company_account_id: companyId,
        company_account_role: role,
        done: done,
        simplify: simplify
    }))
}

//批量确认账单开票
export function updateAccountInvoice(params) {
    let {
        orderId,
        companyId,
        done,
        role,
        simplify, currency
    } = params

    return httpPostFormData('/admin/statements/update_invoice', toFormData({
        order_id: orderId,
        company_account_id: companyId,
        company_account_role: role,
        done: done,
        simplify: simplify,
        currency: currency
    }))
}

//批量确认用户支付
export function updateAccountPaid(params) {
    let {
        orderId,
        companyId,
        done,
        role,
        simplify,
        currency
    } = params

    return httpPostFormData('/admin/statements/update_paid', toFormData({
        order_id: orderId,
        company_account_id: companyId,
        company_account_role: role,
        done: done,
        simplify: simplify,
        currency: currency
    }))
}

//批量确认用户支付审核通过，用户支付成功
export function updateAccountCofirmPaid(params) {
    let {
        orderId,
        companyId,
        role,
        simplify,
        done,
        currency,
        long
    } = params
    console.log(params)
    return httpPostFormData('/admin/statements/update_paid_confirm', toFormData({
        order_id: orderId,
        company_account_id: companyId,
        company_account_role: role,
        done: done,
        simplify: simplify,
        currency: currency,
        time: long
    }))
}

//通过银行流水号获取相对应的数据
export function getBankNumberData(bankNumber) {
    return httpGet("/company_transaction/by/bank_transaction/" + bankNumber)
}

//创建银行流水号
export function createBankFlowData(params) {
    let {
        company_id,
        bankTransactionId,
        currency,
        flow,
        reference,
        remark,
        transactionType,
        value
    } = params

    return httpPostFormData('/company_transaction/create', toFormData({
        company_account_id: company_id,
        bank_transaction_id: bankTransactionId,
        value: value,
        transaction_type: transactionType,
        remark: remark,
        reference: reference,
        currency: currency,
        transaction_flow: flow
    }))
}

//更新 CompanyAccountTransaction meta信息
export function updateBankInfo(params) {
    let {
        bankTransactionId,
        companyAccountId,
        id,
        reference,
        remark,
        transactionType,
    } = params
    return httpPostFormData("/company_transaction/update_meta", toFormData({
        id: id,
        company_account_id: companyAccountId,
        bank_transaction_id: bankTransactionId,
        transaction_type: transactionType,
        reference: reference,
        remark: remark
    }))
}

//更新 CompanyAccountTransaction 数值信息
export function updateBankFlow(params) {
    let {
        currency,
        flow,
        id,
        value
    } = params
    return httpPostFormData("/company_transaction/update_value", toFormData({
        id: id,
        currency: currency,
        flow: flow,
        value: value

    }))

}

//修改订单remark字段
export function setOrderRemarkText(params) {
    let {
        remark,
        order_id,
        expose_price
    } = params
    return httpPostFormData("/admin/order/remark", toFormData({
        order_id: order_id,
        remark: remark,
        expose_price
    }))
}

//获取订单下账单
export function getOrderAccount(id) {
    return httpGet(`/admin/statements/content/by/order/${id}`)
}

//获取账单信息
export function getAccountInfo(id, data) {
    return httpGet(`/admin/statement/${id}`, data)
}

// 根据多个订单id获取账单信息
export function getOrdersAccountInfo(ids) {
    let {
        id,

    } = ids
    return httpPostFormData("/admin/statements/content/by/order/order_ids", toFormData({
        order_ids: id,

    }))
}

//查询账单模版
export function getAccountTemplate(data) {
    // let {
    //     template_name,
    //     company_name,
    //     company_role,
    //     type,
    //     page,
    //     size
    // } = data
    console.log(data, 'formSearch')
    return httpGet("/admin/statement_template/query", data)
}

//新增账单模版
export function createAccountTemplate(params) {

    return httpPostFormData("/admin/statement_template/create", toFormData(params))
}

//更改账单模版
export function editAccountTemplate(params) {

    return httpPostFormData("/admin/statement_template/edit", toFormData(params))
}

//删除账单模版
export function deleteAccountTemplate(id) {
    return httpDeleate_v2(`/admin/statement_template/delete/${id}`)
}

//拷贝订单
export function copyOrder(params) {
    return httpPostFormData('/admin/order/duplicate', toFormData(params))
}

//拷贝报价单
export function copyBill(params) {
    return httpPostFormData('/quotation/duplicate', toFormData(params))
}

// 获取报价单信息
export function getBillOriginQuery(id) {
    return httpGet(`/quotation/revisions/${id}`)
}

export function getExpressBillOriginQuery(id) {
    return httpGet(`/quotation/exp/revisions/${id}`)
}

//拷贝快递快递（express）报价单信息
export function setExpressBill(params) {
    return httpPostFormData(`/quotation/exp/duplicate`, toFormData(params))
}

//获取现舱信息列表
export function getFreightSpace() {
    return httpGet(`/freight_space/admin/list`)
}

//上传现舱信息
export function setFreightSpace(params) {
    return httpPostRequstBody('/freight_space/upload', params)
}


// 更新账单display No
export function updateAccountNo(params) {
    let {
        accountId,
        accountDisplayNo,
        simplify
    } = params
    return httpPostFormData(`/admin/statement/${accountId}/update/display_no`, toFormData({
        id: accountId,
        display_no: accountDisplayNo,
        simplify: simplify
    }))
}

//updata account remark
export function updateAccountRemark(params) {
    let {
        accountId,
        remark,
        simplify
    } = params
    return httpPostFormData(`/admin/statement/${accountId}/update_remark`, toFormData({
        id: accountId,
        remark: remark,
        simplify: simplify
    }))
}

export function setDummyPlan(params, url) {


    let {
        pol,
        pod,
        transportType,
        transshipment,
        weekNumber,
        rvf,
        rve,
        from_address,
        to_address,
        weightRate,
        itemTypeId,
        searchableItems
    } = params
    return httpPostFormData(url, toFormData({
        transport_type: transportType,
        pol: pol,
        pod: pod,
        searchable_items: searchableItems,
        transshipment: transshipment,
        etd: weekNumber,
        rvf: rvf,
        rve: rve,
        weight_rate: weightRate,
        item_type_id: itemTypeId,
        from_address: from_address,
        to_address: to_address
    }))
}

// 获取月度账单报表列表
export function getStatementsReports(params, async = false) {
    const { from, to, filteredCompanyIds, include_trading_agent_records } = params
    if (async) {
        return httpPostFormData('/admin/statements/reports_async', toFormData({
            from,
            to,
            filtered_company_ids: filteredCompanyIds,
            include_trading_agent_records,
        }))
    }
    return httpPostFormData('/admin/statements/reports', toFormData({
        from,
        to,
        filtered_company_ids: filteredCompanyIds,
        include_trading_agent_records,
    }))
}

// 获取月度账单报表列表（ATD筛选）
export function getStatementsReportsAtd(params, async = false) {
    const { from, to, filteredCompanyIds, include_trading_agent_records } = params
    if (async) {
        return httpPostFormData('/admin/statements/atd_reports_async', toFormData({
            from,
            to,
            filtered_company_ids: filteredCompanyIds,
            include_trading_agent_records,
        }))
    }
    return httpPostFormData('/admin/statements/atd_reports', toFormData({
        from,
        to,
        filtered_company_ids: filteredCompanyIds,
        include_trading_agent_records,
    }))
}

//获取closed订单报表
export function getStatementsReportClosed(params, async = false) {
    const { from, to, filteredCompanyIds, include_trading_agent_records } = params
    if (async) {
        return httpPostFormData('/admin/statements/closed_reports_async', toFormData({
            from,
            to,
            filtered_company_ids: filteredCompanyIds,
            include_trading_agent_records,
        }))
    }
    return httpPostFormData('/admin/statements/closed_reports', toFormData({
        from,
        to,
        filtered_company_ids: filteredCompanyIds,
        include_trading_agent_records,
    }))
}

// 独立贸易代理报表
export function getTrading_agentReports(params) {
    const { from, to, filteredCompanyIds } = params
    return httpPostFormData('/admin/trading_agent/reports', toFormData({
        from,
        to,
        filtered_company_ids: filteredCompanyIds,
    }))
}

// 获取某年某月自定义汇率
export function getMonthlyCustomerCurrency(params) {
    const { year, month } = params
    return httpGet('/admin/statements/monthly/custom_currency', {
        year,
        month,
    })
}

// 修改某年某月自定义汇率
export function updateMonthlyCustomerCurrency(params) {
    const { year, month, currency_map } = params
    return httpPostFormData('/admin/statements/monthly/custom_currency/edit', toFormData({
        year,
        month,
        currency_map,
    }))
}

// 修改订单状态
export function updateOrderStatus(params) {
    const { order_id, status } = params
    return httpPostFormData('/admin/order/status/update', toFormData({
        order_id, status
    }))
}

// 同意订单取消
export function agreeOrderCancel(params) {
    const { order_id, reason } = params
    return httpPostFormData('/admin/order/status/cancel/agree', toFormData({
        order_id, reason
    }))
}

// 驳回订单取消
export function denyOrderCancel(params) {
    const { order_id, reason } = params
    return httpPostFormData('/admin/order/status/cancel/deny', toFormData({
        order_id, reason
    }))
}

//获取所有联系人
export function getContacts(target_user_id, type) {

    return httpGet('/user/contacts', {
        target_user_id,
        type,
        size: 999,
    })
}

//更新shipper信息
export function changeShipper(params) {
    let {
        orderId,
        userContactId
    } = params
    return httpPostFormData('/admin/order/shipper/change', toFormData({
        order_id: orderId,
        user_contact_id: userContactId
    }))
}

//更新consignee信息
export function changeConsignee(params) {
    let {
        orderId,
        userContactId
    } = params
    return httpPostFormData('/admin/order/consignee/change', toFormData({
        order_id: orderId,
        user_contact_id: userContactId
    }))
}

//更新notifier信息
export function changeNotifier(params) {
    let {
        orderId,
        userContactId
    } = params
    return httpPostFormData('/admin/order/notifier/change', toFormData({
        order_id: orderId,
        user_contact_id: userContactId
    }))
}

//账单收付款完成
export function allAccountConfirm(params) {
    let {
        id,
        currency,
        time,
        done,
        simplify
    } = params
    return httpPostFormData(`/admin/statement/${id}/update_paid_confirm`, toFormData({
        id,
        currency,
        time,
        done,
        simplify
    }))
}


//将账单设置为已读
export function doneReadAccount(ids) {
    return httpPostFormData('/admin/statements/mark/already_read', toFormData({
        statement_ids: ids
    }))
}


//将账单设置为已读
export function noReadAccount(ids) {
    return httpPostFormData('/admin/statements/mark/still_unread', toFormData({
        statement_ids: ids
    }))
}

//或许最新账单是vendor的订单账单
export function getLastRoleIsVendorStatement() {
    return httpGet('/admin/statements/last_vendor_statement')
}

//或许最新账单是vendor的订单账单数量
export function getLastRoleIsVendorStatementNumber() {
    return httpGet('/admin/statements/last_vendor_statement/count')
}

// empty statement order
export function getEmptyStatementOrders() {
    return httpGet('/admin/statements/empty', { 'from': 1640966400000 });
}

// empty statement order count
export function getEmptyStatementOrderCount() {
    return httpGet('/admin/statements/empty/count', { 'from': 1640966400000 });
}

// 获取待催款账单
export function getPendingBills() {
    return httpGet('/admin/statements/overdue');
}

// 获取待催款账单数量
export function getPendingBillsCount() {
    return httpGet('/admin/statements/overdue/count');
}

// 获取应收款账单
export function getPendingUnfinished() {
    return httpGet('/admin/statements/unfinished');
}

// 获取应收款账单数量
export function getPendingUnfinishedCount() {
    return httpGet('/admin/statements/unfinished/count');
}

// 更新应付款日
export function updatePatDate(params) {
    const {
        should_pay_at,
        simplify,
        id,
    } = params;
    return httpPostFormData(`/admin/statement/${id}/update/pay_date`, toFormData({
        should_pay_at,
        simplify,
    }));
}

// 账单确认状态更新
export function updateConfirmStauts(params) {
    const {
        id,
        done,
    } = params;
    return httpPostFormData(`/admin/statement/${id}/update_bill_confirm`, toFormData({
        id,
        done,
    }));
}

export function getLastRoleIsVendorStatementOrderData(params) {
    let {
        ids,
        exposePrice,
    } = params
    return httpPostFormData('/orders/info', toFormData({
        ids,
        expose_price: exposePrice
    }))
}

//增加收发通
export function addInfo(params) {
    let {
        target_user_id,
        email,
        name,
        address,
        type,
        phone
    } = params
    return httpPostFormData('/user/contacts', toFormData({
        target_user_id,
        name,
        address,
        type,
        phone,
        email
    }))
}

//修改收发通
export function editInfo(params) {
    let {
        id,
        email,
        name,
        address,
        phone
    } = params
    return httpPostFormData(`/user/contacts/${id}`, toFormData({
        id,
        email,
        name,
        address,
        phone
    }))
}

//财务查询订单closed
export function financeOrderClosed(params) {
    let {
        orderId,
        closeTime
    } = params
    return httpPostFormData('/admin/statements/order/close', toFormData({
        order_id: orderId,
        close_time: closeTime
    }))
}

//财务查询订单reopen
export function financeOrderReopen(params) {
    let {
        orderId,
    } = params
    return httpPostFormData('/admin/statements/order/reopen', toFormData({
        order_id: orderId,
    }))
}

export const getDocTemplate = (params) => {
    let { query, transport_type, bind_status, page, size } = params
    return httpGet(`/admin/template/status/doc/query`, { query, transport_type, bind_status, page, size })
}

export const getDocTemplateGroup = (params) => {
    let { query, page, size } = params
    return httpGet(`/admin/template/group/status/doc/query`, { query, page, size })
}


// todo
export const delDocTemplate = (order_id, doc_id) => {
    return httpDeleate_v2("/admin/order/status/doc/delete", { order_id, doc_id })
}


