import Vue from 'vue'
import store from '@/store'
import { isString, isArray } from '@/util/validate'
import settings from '@/util/settings'

// you can set in settings.js
// errorLog:'production' | ['production', 'development']
const { errorLog: needErrorLog } = settings

function checkNeed() {
  const env = process.env.NODE_ENV
  if (isString(needErrorLog)) {
    return env === needErrorLog
  }
  if (isArray(needErrorLog)) {
    return needErrorLog.includes(env)
  }
  return false
}
console.log("我在error-log.js中-----------",checkNeed(),needErrorLog,store)
if (checkNeed()) {
  Vue.config.errorHandler = function(err, vm, info, a) {
    if(err.message == 'e is not a function') return  // 去除指定报错
    if(err.message == 'yielded is not defined') return  // 去除指定报错
    // console.log("我进来了")
    // console.dir(err,"err---")
    // console.dir(vm,"vm---")
    // console.dir(info,"info---")
  // Don't ask me why I use Vue.nextTick, it just a hack.
  // detail see https://forum.vuejs.org/t/dispatch-in-vue-config-errorhandler-has-some-problem/23500
    Vue.nextTick(() => {
      store.dispatch('addErrorLog', {
        err,
        vm,
        info,
        url: window.location.href
      })
      console.error(err, info,"我是捕获触发之后的错误")
      console.log(err, info,"我是捕获触发之后的错误")
    })
  }
  window.onerror = function(errorMessage, scriptURI, lineNumber,columnNumber,errorObj) {
    // console.log("错误信息：" , errorMessage);
    // console.log("出错文件：" , scriptURI);
    // console.log("出错行号：" , lineNumber);
    // console.log("出错列号：" , columnNumber);
    // console.log("错误详情：" , errorObj);
    // console.dir(errorMessage)
    // console.dir(scriptURI)
    // console.dir(lineNumber)
    // console.dir(columnNumber)
    // console.dir(errorObj)
    if(errorObj.message == 'e is not a function') return  // 去除指定报错
    if(errorObj.message == 'yielded is not defined') return  // 去除指定报错
    Vue.nextTick(() => {
      store.dispatch('addErrorLog', {
        err:errorObj,
        vm:{$vnode:{tag:'window:'}},
        info:scriptURI,
        url: window.location.href
      })
      // console.error(err, info,"我是捕获触发之后的错误")
    })
  }
}
