/* 该表为计算的 key 配置表
 * 
 */
export default {
  "currency": { type: 1, key: "currency", alias: "货币(该项货币单位不一样时添加)" },
  "20gp": { type: 2, key: "20gp", alias: '20GP箱子' },
  "40gp": { type: 2, key: "40gp", alias: '40GP箱子' },
  "40hq": { type: 2, key: "40hq", alias: '40HQ箱子' },
  "min": { type: 2, key: "min", alias: "最小收费" },
  "cbm": { type: 2, key: "cbm", alias: "立方数" },
  "ton": { type: 2, key: "ton", alias: "吨数" },
  "rt": { type: 2, key: "rt", alias: "计费吨数" },
  "ckg": { type: 2, key: "ckg", alias: "计费公斤数" },
  "bl": { type: 2, key: "bl", alias: "票数" },
  "including": { type: 2, key: "including", alias: "起始项包含" },
  "doc": { type: 2, key: "doc", alias: "票数" },
  "hs": { type: 2, key: "hs", alias: "品名数" },
  "cntr": { type: 2, key: "cntr", alias: "箱子数" },
}