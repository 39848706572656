export const MenuConfig = [
  {
    group: "SYS",
    groupName: "系统设置",
    icon: "el-icon-setting",
    index: "1",
    menus: [
      {
        path: "/manage/port",
        index: "manageport",
        name: "港口管理",
        component: import("@/components/system/SystemConfig/Port/index.vue"),
        userRoles: ["SYS_ADMIN", "ADMIN_LEVEL_Z", "ADMIN_LEVEL_Y"],
      },
      {
        path: "/manage/box",
        index: "managebox",
        name: "集装箱配置",
        component: import("@/components/system/SystemConfig/Box/index.vue"),
        userRoles: ["SYS_ADMIN", "ADMIN_LEVEL_Z", "ADMIN_LEVEL_Y"],
      },
      {
        path: "/manage/air",
        index: "manageair",
        name: "机场列表",
        component: import("@/components/system/SystemConfig/Air/index.vue"),
        userRoles: ["SYS_ADMIN", "ADMIN_LEVEL_Z", "ADMIN_LEVEL_Y"],
      },
      {
        path: "/manage/trucks",
        index: "managetrucks",
        name: "卡车配置",
        component: import("@/components/system/SystemConfig/Truck/index.vue"),
        userRoles: ["SYS_ADMIN", "ADMIN_LEVEL_Z", "ADMIN_LEVEL_Y"],
      },
      {
        path: "/manage/goods",
        index: "managegoods",
        name: "货物类型",
        component: import("@/components/system/SystemConfig/Goods/index.vue"),
        userRoles: ["SYS_ADMIN", "ADMIN_LEVEL_Z", "ADMIN_LEVEL_Y"],
      },
      {
        path: "/manage/rate",
        index: "managerate",
        name: "汇率管理",
        component: import(
          "@/components/system/SystemConfig/CurrencyRate/index.vue"
        ),
        userRoles: ["SYS_ADMIN", "ADMIN_LEVEL_Z", "ADMIN_LEVEL_Y"],
      },
      {
        path: "/manage/search_sort",
        index: "search_sort",
        name: "搜索排序管理",
        component: import("@/components/system/Search/sort.vue"),
        userRoles: ["SYS_ADMIN", "ADMIN_LEVEL_Z", "ADMIN_LEVEL_Y"],
      },
      {
        path: "/manage/financial_account",
        index: "financial_account",
        name: "会计科目",
        component: import(
          "@/components/system/SystemConfig/FinancialAccount/index.vue"
        ),
        userRoles: [
          "SYS_ADMIN",
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_Y",
          "ADMIN_LEVEL_N",
          "ADMIN_LEVEL_M",
          "ADMIN_LEVEL_B",
          "ADMIN_LEVEL_A",
          "ADMIN_LEVEL_O",
          "ADMIN_LEVEL_P",
          "ADMIN_LEVEL_C",
          "ADMIN_LEVEL_D",
        ],
      },
      //Margin配置
      {
        path: "/manage/margin_config",
        index: "margin_config",
        name: "Margin配置",
        component: import(
          "@/components/system/SystemConfig/marginConfig/index.vue"
        ),
        userRoles: [
          "SYS_ADMIN",
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_Y",
          "ADMIN_LEVEL_N",
          "ADMIN_LEVEL_M",
        ],
      },
      {
        path: "/manage/shipPath",
        index: "shipPath",
        name: "轮船路径",
        component: import(
          "@/components/system/SystemConfig/shipPath/index.vue"
        ),
        userRoles: [
          "SYS_ADMIN",
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_Y",
          "ADMIN_LEVEL_N",
          "ADMIN_LEVEL_M",
        ],
      },
      {
        path: "/manage/portConfig",
        index: "portConfig",
        name: "港口配置",
        hidden: process.env.VUE_APP_MODE === "production",
        component: import(
          "@/components/system/SystemConfig/portConfig/index.vue"
        ),
        userRoles: [
          "SYS_ADMIN",
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_Y",
          "ADMIN_LEVEL_N",
          "ADMIN_LEVEL_M",
        ],
      },
      {
        path: "/manage/vesselShipped",
        index: "vesselShipped",
        name: "船司付款配置",
        component: import(
          "@/components/system/SystemConfig/VesselShipped/index.vue"
        ),
        userRoles: [
          "SYS_ADMIN",
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_Y",
          "ADMIN_LEVEL_N",
          "ADMIN_LEVEL_M",
          "ADMIN_LEVEL_P",
          "ADMIN_LEVEL_O",
        ],
      },
      {
        path: "/manage/systemConfig",
        index: "systemConfig",
        name: "菜单配置",
        component: import(
          "@/components/system/SystemConfig/systemConfig/index.vue"
        ),
        userRoles: [
          "SYS_ADMIN",
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_Y",
          "ADMIN_LEVEL_N",
          "ADMIN_LEVEL_M",
          "ADMIN_LEVEL_B",
          "ADMIN_LEVEL_A",
          "ADMIN_LEVEL_O",
          "ADMIN_LEVEL_P",
          "ADMIN_LEVEL_C",
          "ADMIN_LEVEL_D",
        ],
      },
      {
        path: "/manage/iframe_manage",
        index: "iframe_manage",
        name: "iframe管理",
        component: import("@/components/system/Iframe/IframeView.vue"),
        userRoles: [
          "SYS_ADMIN",
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_Y",
          "ADMIN_LEVEL_N",
          "ADMIN_LEVEL_M",
          "ADMIN_LEVEL_B",
          "ADMIN_LEVEL_A",
          "ADMIN_LEVEL_I",
          "ADMIN_LEVEL_J",
        ],
      },
      {
        path: "/manage/open_api_manage",
        index: "open_api_manage",
        name: "Open API管理",
        component: import("@/components/system/OpenApi/index.vue"),
        userRoles: [
          "SYS_ADMIN",
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_Y",
          "ADMIN_LEVEL_N",
          "ADMIN_LEVEL_M",
          "ADMIN_LEVEL_B",
          "ADMIN_LEVEL_A",
          "ADMIN_LEVEL_I",
          "ADMIN_LEVEL_J",
        ],
      },
    ],
  },
  {
    group: "ANLYS",
    groupName: "行为分析",
    icon: "el-icon-s-marketing",
    index: "3",
    menus: [
      {
        path: "/manage/user",
        index: "userinfo",
        name: "用户管理 (Customer端)",
        component: import(
          "@/components/system/BehaviorAnalysis/User/index.vue"
        ),
        userRoles: [
          "SYS_ADMIN",
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_Y",
          "ADMIN_LEVEL_I",
          "ADMIN_LEVEL_J",
          "ADMIN_LEVEL_D",
          "ADMIN_LEVEL_C",
          "ADMIN_LEVEL_B",
          "ADMIN_LEVEL_A",
          "ADMIN_LEVEL_N",
          "ADMIN_LEVEL_M",
        ],
      },
      {
        path: "/manage/pms_supplier_info",
        index: "pms_supplier_info",
        name: "供应商用户",
        userRoles: [
          "SYS_ADMIN",
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_Y",
          "ADMIN_LEVEL_D",
          "ADMIN_LEVEL_C",
        ],
        link: "/manage/pms_supplier",
      },
      {
        path: "/manage/query",
        index: "userquery",
        name: "查询管理",
        component: import(
          "@/components/system/BehaviorAnalysis/Query/index.vue"
        ),
        userRoles: [
          "SYS_ADMIN",
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_Y",
          "ADMIN_LEVEL_I",
          "ADMIN_LEVEL_J",
          "ADMIN_LEVEL_D",
          "ADMIN_LEVEL_C",
          "ADMIN_LEVEL_B",
          "ADMIN_LEVEL_A",
        ],
      },
      {
        path: "/manage/system_statistics",
        index: "system_statistics",
        name: "系统统计",
        component: import(
          "@/components/system/BehaviorAnalysis/SystemStatistics/index.vue"
        ),
        userRoles: [
          "SYS_ADMIN",
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_Y",
          "ADMIN_LEVEL_I",
          "ADMIN_LEVEL_J",
        ],
      },
      {
        path: "/manage/subscription_email",
        index: "subscription_email",
        name: "邮件推送",
        component: import(
          "@/components/system/BehaviorAnalysis/SubscriptionEmail/index.vue"
        ),
        userRoles: [
          "SYS_ADMIN",
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_Y",
          "ADMIN_LEVEL_I",
          "ADMIN_LEVEL_J",
          "ADMIN_LEVEL_D",
          "ADMIN_LEVEL_C",
          "ADMIN_LEVEL_B",
          "ADMIN_LEVEL_A",
        ],
      },
      {
        path: "/manage/cohort",
        index: "cohort",
        name: "cohort统计",
        component: import(
          "@/components/system/BehaviorAnalysis/Cohort/index.vue"
        ),
        userRoles: ["SYS_ADMIN", "ADMIN_LEVEL_Z", "ADMIN_LEVEL_Y"],
      },
      {
        path: "/manage/gpt_list",
        index: "gpt_list",
        name: "EZGpt聊天记录",
        component: import(
          "@/components/system/BehaviorAnalysis/GptList/index.vue"
        ),
        userRoles: [
          "SYS_ADMIN",
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_Y",
          "ADMIN_LEVEL_I",
          "ADMIN_LEVEL_J",
          "ADMIN_LEVEL_D",
          "ADMIN_LEVEL_C",
          "ADMIN_LEVEL_B",
          "ADMIN_LEVEL_A",
          "ADMIN_LEVEL_N",
          "ADMIN_LEVEL_M",
        ],
      },
      {
        path: "/manage/gpt_purchase_history",
        index: "gpt_purchase_history",
        name: "EZGpt付款记录",
        component: import(
          "@/components/system/BehaviorAnalysis/GptPurchaseHistory/index.vue"
        ),
        userRoles: [
          "SYS_ADMIN",
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_Y",
          "ADMIN_LEVEL_I",
          "ADMIN_LEVEL_J",
          "ADMIN_LEVEL_D",
          "ADMIN_LEVEL_C",
          "ADMIN_LEVEL_B",
          "ADMIN_LEVEL_A",
          "ADMIN_LEVEL_N",
          "ADMIN_LEVEL_M",
        ],
      },
      {
        path: "/manage/email_list",
        index: "email_list",
        name: "邮件助手使用记录",
        component: import(
          "@/components/system/BehaviorAnalysis/EmailList/index.vue"
        ),
        userRoles: [
          "SYS_ADMIN",
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_Y",
          "ADMIN_LEVEL_I",
          "ADMIN_LEVEL_J",
          "ADMIN_LEVEL_D",
          "ADMIN_LEVEL_C",
          "ADMIN_LEVEL_B",
          "ADMIN_LEVEL_A",
          "ADMIN_LEVEL_N",
          "ADMIN_LEVEL_M",
        ],
      },
      {
        path: "/manage/email_purchase_history",
        index: "email_purchase_history",
        name: "邮件助手付款记录",
        component: import(
          "@/components/system/BehaviorAnalysis/EmailPurchaseHistory/index.vue"
        ),
        userRoles: [
          "SYS_ADMIN",
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_Y",
          "ADMIN_LEVEL_I",
          "ADMIN_LEVEL_J",
          "ADMIN_LEVEL_D",
          "ADMIN_LEVEL_C",
          "ADMIN_LEVEL_B",
          "ADMIN_LEVEL_A",
          "ADMIN_LEVEL_N",
          "ADMIN_LEVEL_M",
        ],
      },
    ],
  },
  {
    group: "ORDER",
    groupName: "操作模块",
    icon: "el-icon-s-order",
    index: "4",
    menus: [
      {
        path: "/manage/order",
        index: "userorder",
        name: "订单管理",
        component: import("@/components/system/Order/index.vue"),
        userRoles: [
          "SYS_ADMIN",
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_Y",
          "ADMIN_LEVEL_B",
          "ADMIN_LEVEL_A",
          "ADMIN_LEVEL_D",
          "ADMIN_LEVEL_C",
        ],
      },
      {
        path: "/manage/order_warm",
        index: "order_warm",
        name: "预警订单管理",
        component: import("@/components/system/Order/index.vue"),
        userRoles: [
          "SYS_ADMIN",
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_Y",
          "ADMIN_LEVEL_B",
          "ADMIN_LEVEL_A",
        ],
      },
      {
        path: "/manage/vendor_account",
        index: "vendor_account",
        name: `最新账单为Vendor`,
        component: import(
          "@/components/system/Order/vendorStatement/index.vue"
        ),
        userRoles: [
          "SYS_ADMIN",
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_Y",
          "ADMIN_LEVEL_B",
          "ADMIN_LEVEL_A",
        ],
      },
      {
        path: "/manage/empty_statement_order",
        index: "empty_statement_order",
        name: `无账单订单`,
        component: import("@/components/system/Order/emptyStatement/index.vue"),
        userRoles: [
          "SYS_ADMIN",
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_Y",
          "ADMIN_LEVEL_B",
          "ADMIN_LEVEL_A",
        ],
      },
      {
        path: "/manage/pendingBills",
        index: "pendingBills",
        name: `待催款账单`,
        component: import("@/components/system/Financial/finaSearch"),
        userRoles: [
          "SYS_ADMIN",
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_Y",
          "ADMIN_LEVEL_B",
          "ADMIN_LEVEL_A",
          "ADMIN_LEVEL_O",
          "ADMIN_LEVEL_P",
          "ADMIN_LEVEL_Y",
        ],
      },
      {
        path: "/manage/unfinished",
        index: "unfinished",
        name: `应收款账单`,
        component: import("@/components/system/Financial/finaSearch"),
        userRoles: [
          "SYS_ADMIN",
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_Y",
          "ADMIN_LEVEL_C",
          "ADMIN_LEVEL_D",
          "ADMIN_LEVEL_A",
          "ADMIN_LEVEL_B",
          "ADMIN_LEVEL_O",
          "ADMIN_LEVEL_P",
        ],
      },
      {
        path: "/manage/statementMerge",
        index: "statementMerge",
        name: "账单合并导出",
        component: import("@/components/system/Financial/finaSearch"),
        userRoles: [
          "SYS_ADMIN",
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_Y",
          "ADMIN_LEVEL_B",
          "ADMIN_LEVEL_A",
        ],
      },
      {
        path: "/manage/quoteRequest",
        index: "quoteRequest",
        name: "核价请求管理",
        component: import("@/components/system/Order/QuoteRequest/index.vue"),
        userRoles: [
          "SYS_ADMIN",
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_Y",
          "ADMIN_LEVEL_B",
          "ADMIN_LEVEL_A",
          "ADMIN_LEVEL_C",
          "ADMIN_LEVEL_D",
        ],
      },
      {
        path: "/manage/account_template",
        index: "/manage/account_template",
        name: "账单模版",
        component: import(
          "@/components/system/Order/Financial/AccountTemplateComponent.vue"
        ),
        userRoles: [
          "SYS_ADMIN",
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_Y",
          "ADMIN_LEVEL_B",
          "ADMIN_LEVEL_A",
          "ADMIN_LEVEL_O",
          "ADMIN_LEVEL_P",
        ],
      },
      {
        path: "/manage/order_tracking",
        index: "order_tracking",
        name: "订单追踪",
        component: import("@/components/system/Order/OrderTracking"),
        userRoles: [
          "SYS_ADMIN",
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_Y",
          "ADMIN_LEVEL_B",
          "ADMIN_LEVEL_A",
          "ADMIN_LEVEL_D",
          "ADMIN_LEVEL_C",
        ],
      },
      // {
      //   path: '/manage/order_tracking_subscribe',
      //   index: 'order_tracking_subscribe',
      //   name: '鲸准追踪订阅管理',
      //   component: import('@/components/system/Order/trackingSubscribe/index.vue'),
      //   userRoles: ['SYS_ADMIN', 'ADMIN_LEVEL_Z', 'ADMIN_LEVEL_Y', 'ADMIN_LEVEL_B', 'ADMIN_LEVEL_A',]
      // },
      {
        path: "/manage/order_docStatusTemplate",
        index: "order_docStatusTemplate",
        name: "文书模板管理",
        component: import(
          "@/components/system/orderStatus/orderStatusTemplate.vue"
        ),
        userRoles: [
          "SYS_ADMIN",
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_Y",
          "ADMIN_LEVEL_B",
          "ADMIN_LEVEL_A",
        ],
      },
      {
        path: "/manage/docTemplateGroup",
        index: "docTemplateGroup",
        name: "文书模板套餐管理",
        component: import(
          "@/components/system/orderStatus/docTemplateGroup.vue"
        ),
        userRoles: [
          "SYS_ADMIN",
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_Y",
          "ADMIN_LEVEL_B",
          "ADMIN_LEVEL_A",
          "ADMIN_LEVEL_D",
          "ADMIN_LEVEL_C",
        ],
      },
      {
        path: "/manage/juniorOperate",
        index: "juniorOperate",
        name: "初级操作员管理",
        component: import("@/components/system/Order/juniorOperate/index.vue"),
        userRoles: [
          "SYS_ADMIN",
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_Y",
          "ADMIN_LEVEL_B",
        ],
      },
      {
        path: "/manage/couponManage",
        index: "couponManage",
        name: "优惠券管理",
        component: import("@/components/system/coupon/index.vue"),
        userRoles: [
          "SYS_ADMIN",
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_Y",
          "ADMIN_LEVEL_N",
          "ADMIN_LEVEL_M",
          "ADMIN_LEVEL_J",
          "ADMIN_LEVEL_I",
          "ADMIN_LEVEL_D",
          "ADMIN_SALES",
          "ADMIN_LEVEL_C",
          "ADMIN_LEVEL_B",
          "ADMIN_LEVEL_P",
          "ADMIN_LEVEL_O",
        ],
      },
    ],
  },
  {
    group: "IMPORT",
    groupName: "价格导入",
    icon: "el-icon-upload",
    index: "5",
    menus: [
      {
        path: "/manage/geekYum_import",
        index: "geekYum_import",
        name: "GeekYum数据导入",
        component: import("@/components/system/DataImport/index.vue"),
        userRoles: [
          "SYS_ADMIN",
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_Y",
          "ADMIN_LEVEL_B",
          "ADMIN_LEVEL_A",
          "ADMIN_LEVEL_D",
          "ADMIN_LEVEL_C",
        ],
      },
      {
        path: "/manage/priceTemplate",
        index: "priceTemplate",
        name: "价格模板下载",
        component: import(
          "@/components/system/Imports/PriceTemplate/index.vue"
        ),
        userRoles: [
          "SYS_ADMIN",
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_Y",
          "ADMIN_LEVEL_N",
          "ADMIN_LEVEL_M",
          "ADMIN_LEVEL_B",
        ],
      },
      {
        path: "/manage/priceimporthistory",
        index: "priceimporthistory",
        name: "导入记录",
        component: import(
          "@/components/system/Imports/ImportActionHistory/index.vue"
        ),
        userRoles: [
          "SYS_ADMIN",
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_Y",
          "ADMIN_LEVEL_N",
          "ADMIN_LEVEL_M",
          "ADMIN_LEVEL_B",
        ],
      },
      {
        path: "/manage/priceimport",
        index: "priceimport",
        name: "价格导入",
        component: import("@/components/system/Imports/PriceImport/index.vue"),
        userRoles: [
          "SYS_ADMIN",
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_Y",
          "ADMIN_LEVEL_N",
          "ADMIN_LEVEL_M",
          "ADMIN_LEVEL_B",
        ],
      },
      {
        path: "/manage/priceimportexp",
        index: "priceimportexp",
        name: "快递价格导入",
        component: import(
          "@/components/system/Imports/PriceImportExp/index.vue"
        ),
        userRoles: [
          "SYS_ADMIN",
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_Y",
          "ADMIN_LEVEL_N",
          "ADMIN_LEVEL_M",
          "ADMIN_LEVEL_B",
        ],
      },
      {
        path: "/manage/interiorPrices",
        index: "interiorPrices",
        name: "内装价格导入",
        component: import(
          "@/components/system/Imports/InteriorPrices/index.vue"
        ),
        userRoles: [
          "SYS_ADMIN",
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_Y",
          "ADMIN_LEVEL_N",
          "ADMIN_LEVEL_M",
          "ADMIN_LEVEL_B",
        ],
      },
    ],
  },
  {
    group: "IMPORT_CONFIG",
    groupName: "现有价格池",
    icon: "el-icon-money",
    index: "6",
    menus: [
      {
        path: "/manage/priceconfig1_5",
        index: "priceconfig1_5",
        name: "1/5段价格",
        component: import(
          "@/components/system/pricePool/PriceConfig/door2Port.vue"
        ),
        userRoles: [
          "SYS_ADMIN",
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_Y",
          "ADMIN_LEVEL_N",
          "ADMIN_LEVEL_M",
          "ADMIN_LEVEL_B",
        ],
      },
      {
        path: "/manage/priceconfig2_4",
        index: "priceconfig2_4",
        name: "2/4段价格",
        component: import(
          "@/components/system/pricePool/PriceConfig/onPort.vue"
        ),
        userRoles: [
          "SYS_ADMIN",
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_Y",
          "ADMIN_LEVEL_N",
          "ADMIN_LEVEL_M",
          "ADMIN_LEVEL_B",
        ],
      },
      {
        path: "/manage/priceconfig3",
        index: "priceconfig3",
        name: "3段价格",
        component: import(
          "@/components/system/pricePool/PriceConfig/port2port.vue"
        ),
        userRoles: [
          "SYS_ADMIN",
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_Y",
          "ADMIN_LEVEL_N",
          "ADMIN_LEVEL_M",
          "ADMIN_LEVEL_B",
        ],
      },
      {
        path: "/manage/priceconfigexpress",
        index: "priceconfigexpress",
        name: "快递价格",
        component: import(
          "@/components/system/pricePool/PriceConfig/express/express.vue"
        ),
        userRoles: [
          "SYS_ADMIN",
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_Y",
          "ADMIN_LEVEL_N",
          "ADMIN_LEVEL_M",
          "ADMIN_LEVEL_B",
        ],
      },
    ],
  },
  {
    group: "IMPORT_PRE",
    groupName: "预处理价格池",
    icon: "el-icon-price-tag",
    index: "7",
    menus: [
      {
        path: "/manage/modify_records",
        index: "modify_records",
        name: "批量修改记录",
        component: import(
          "@/components/system/pricePool/PricePretreatment/modify_records.vue"
        ),
        userRoles: [
          "SYS_ADMIN",
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_Y",
          "ADMIN_LEVEL_N",
          "ADMIN_LEVEL_M",
          "ADMIN_LEVEL_B",
        ],
      },
      {
        path: "/manage/pricepre1_5",
        index: "pricepre1_5",
        name: "1/5段价格",
        component: import(
          "@/components/system/pricePool/PricePretreatment/door2Port.vue"
        ),
        userRoles: [
          "SYS_ADMIN",
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_Y",
          "ADMIN_LEVEL_N",
          "ADMIN_LEVEL_M",
          "ADMIN_LEVEL_B",
        ],
      },
      {
        path: "/manage/pricepre2_4",
        index: "pricepre2_4",
        name: "2/4段价格",
        component: import(
          "@/components/system/pricePool/PricePretreatment/onPort.vue"
        ),
        userRoles: [
          "SYS_ADMIN",
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_Y",
          "ADMIN_LEVEL_N",
          "ADMIN_LEVEL_M",
          "ADMIN_LEVEL_B",
        ],
      },
      {
        path: "/manage/pricepre3",
        index: "pricepre3",
        name: "3段价格",
        component: import(
          "@/components/system/pricePool/PricePretreatment/port2port.vue"
        ),
        userRoles: [
          "SYS_ADMIN",
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_Y",
          "ADMIN_LEVEL_N",
          "ADMIN_LEVEL_M",
          "ADMIN_LEVEL_B",
        ],
      },
      {
        path: "/manage/pricepreexpress",
        index: "pricepreexpress",
        name: "快递价格",
        component: import(
          "@/components/system/pricePool/PricePretreatment/express.vue"
        ),
        userRoles: [
          "SYS_ADMIN",
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_Y",
          "ADMIN_LEVEL_N",
          "ADMIN_LEVEL_M",
          "ADMIN_LEVEL_B",
        ],
      },
    ],
  },
  {
    group: "IMPORT_SUPPLIER",
    groupName: "供应商价格",
    icon: "el-icon-discount",
    index: "8",
    menus: [
      {
        path: "/manage/pricesupplier_port",
        index: "pricesupplier_port",
        name: "港口维护",
        component: import(
          "@/components/system/pricePool/PriceSupplier/portMaintain.vue"
        ),
        userRoles: [
          "SYS_ADMIN",
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_Y",
          "ADMIN_LEVEL_N",
          "ADMIN_LEVEL_M",
          "ADMIN_LEVEL_B",
        ],
      },
      {
        path: "/manage/pricesupplier_airport",
        index: "pricesupplier_airport",
        name: "机场维护",
        component: import(
          "@/components/system/pricePool/PriceSupplier/airportMaintain.vue"
        ),
        userRoles: [
          "SYS_ADMIN",
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_Y",
          "ADMIN_LEVEL_N",
          "ADMIN_LEVEL_M",
          "ADMIN_LEVEL_B",
        ],
      },
      {
        path: "/manage/pricesupplier_history",
        index: "pricesupplier_history",
        name: "导入记录",
        component: import(
          "@/components/system/pricePool/PriceSupplier/history.vue"
        ),
        userRoles: [
          "SYS_ADMIN",
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_Y",
          "ADMIN_LEVEL_N",
          "ADMIN_LEVEL_M",
          "ADMIN_LEVEL_B",
        ],
      },
      {
        path: "/manage/pricesupplier2_4",
        index: "pricesupplier2_4",
        name: "2/4段价格",
        component: import(
          "@/components/system/pricePool/PriceSupplier/onPort.vue"
        ),
        userRoles: [
          "SYS_ADMIN",
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_Y",
          "ADMIN_LEVEL_N",
          "ADMIN_LEVEL_M",
          "ADMIN_LEVEL_B",
        ],
      },
      {
        path: "/manage/pricesupplier3",
        index: "pricesupplier3",
        name: "3段价格",
        component: import(
          "@/components/system/pricePool/PriceSupplier/port2port.vue"
        ),
        userRoles: [
          "SYS_ADMIN",
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_Y",
          "ADMIN_LEVEL_N",
          "ADMIN_LEVEL_M",
          "ADMIN_LEVEL_B",
        ],
      },
    ],
  },
  {
    group: "SALES",
    groupName: "销售模块",
    icon: "el-icon-phone-outline",
    index: "9",
    menus: [
      {
        path: "/manage/quotation",
        index: "sales_quotation",
        name: "报价单管理",
        component: import("@/components/system/Sales/Quotation/index.vue"),
        userRoles: [
          "SYS_ADMIN",
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_Y",
          "ADMIN_LEVEL_D",
          "ADMIN_LEVEL_C",
        ],
      },
      {
        path: "/manage/freight_space",
        index: "freight_space",
        name: "现舱管理",
        component: import("@/components/system/Sales/freightSpace/index.vue"),
        userRoles: [
          "SYS_ADMIN",
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_Y",
          "ADMIN_LEVEL_N",
          "ADMIN_LEVEL_M",
          "ADMIN_LEVEL_C",
          "ADMIN_LEVEL_D",
        ],
      },
      {
        path: "/manage/user_allocation",
        index: "user_allocation",
        name: "客户分配",
        component: import("@/components/system/Sales/user/index.vue"),
        userRoles: [
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_Y",
          "ADMIN_LEVEL_D",
          "ADMIN_LEVEL_N",
          "ADMIN_LEVEL_M",
        ],
      },
      {
        path: "/manage/user_manage",
        index: "user_manage",
        name: "客户管理",
        component: import("@/components/system/Sales/user/index.vue"),
        userRoles: [
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_Y",
          "ADMIN_LEVEL_C",
          "ADMIN_LEVEL_D",
        ],
      },
    ],
  },
  {
    group: "PMS",
    groupName: "PMS管理",
    icon: "el-icon-set-up",
    index: "10",
    menus: [
      {
        path: "/manage/pms_supplier",
        index: "pms_supplier",
        name: "供应商管理",
        component: import("@/components/system/PMS/supplier.vue"),
        userRoles: [
          "SYS_ADMIN",
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_Y",
          "ADMIN_LEVEL_D",
          "ADMIN_LEVEL_C",
          "ADMIN_LEVEL_N",
          "ADMIN_LEVEL_M",
          "ADMIN_LEVEL_B",
          "ADMIN_LEVEL_A",
        ],
      },
      {
        path: "/manage/supplier_collector",
        index: "supplier_collector",
        name: "供应商资料",
        component: import("@/components/system/PMS/supplierCollector.vue"),
        userRoles: [
          "SYS_ADMIN",
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_Y",
          "ADMIN_LEVEL_D",
          "ADMIN_LEVEL_C",
          "ADMIN_LEVEL_B",
        ],
      },
      {
        path: "/manage/pms_team",
        index: "pms_team",
        name: "团队查询",
        component: import("@/components/system/PMS/team.vue"),
        userRoles: [
          "SYS_ADMIN",
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_Y",
          "ADMIN_LEVEL_N",
          "ADMIN_LEVEL_M",
          "ADMIN_LEVEL_B",
          "ADMIN_LEVEL_A",
        ],
      },
      {
        path: "/manage/pms_inviteCode",
        index: "pms_inviteCode",
        name: "邀请码管理",
        component: import("@/components/system/PMS/inviteCode.vue"),
        userRoles: [
          "SYS_ADMIN",
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_Y",
          "ADMIN_LEVEL_N",
          "ADMIN_LEVEL_M",
          "ADMIN_LEVEL_B",
          "ADMIN_LEVEL_A",
        ],
      },
      {
        path: "/manage/pms_ecomm",
        index: "pms_ecomm",
        name: "跨境电商团队",
        component: import("@/components/system/PMS/ecomm/index.vue"),
        userRoles: [
          "SYS_ADMIN",
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_Y",
          "ADMIN_LEVEL_N",
          "ADMIN_LEVEL_M",
          "ADMIN_LEVEL_B",
          "ADMIN_LEVEL_A",
        ],
      },
      {
        path: "/manage/pms_warehouse",
        index: "pms_warehouse",
        name: "仓库列表",
        component: import("@/components/system/PMS/warehouse/index.vue"),
        userRoles: [
          "SYS_ADMIN",
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_Y",
          "ADMIN_LEVEL_N",
          "ADMIN_LEVEL_M",
          "ADMIN_LEVEL_B",
          "ADMIN_LEVEL_A",
        ],
      },
    ],
  },
  {
    group: "DSCFI",
    groupName: "DSCFI管理",
    icon: "el-icon-s-data",
    index: "11",
    menus: [
      {
        path: "/manage/dscfi_team",
        index: "dscfi_team",
        name: "团队管理",
        component: import("@/components/system/dscfi/team/index.vue"),
        userRoles: [
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_Y",
          "ADMIN_LEVEL_C",
          "ADMIN_LEVEL_D",
        ],
      },
      {
        path: "/manage/dscfi_import",
        index: "dscfi_import",
        name: "第三方指数数据上传",
        component: import("@/components/system/dscfi/data/ImportData.vue"),
        userRoles: [
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_Y",
          "ADMIN_LEVEL_C",
          "ADMIN_LEVEL_D",
        ],
      },
      {
        path: "/manage/dscfi_data",
        index: "dscfi_data",
        name: "第三方指数数据管理",
        component: import("@/components/system/dscfi/data/DscfiData.vue"),
        userRoles: [
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_Y",
          "ADMIN_LEVEL_C",
          "ADMIN_LEVEL_D",
        ],
      },
    ],
  },
  // TODO 正式暂时需要隐藏账单相关功能（未完整通过测试)
  {
    group: "PRICE",
    groupName: "财务管理",
    icon: "el-icon-trophy",
    index: "12",
    menus: [
      {
        path: "/manage/financial_search",
        index: "financial_search",
        name: "财务查询",
        component: import("@/components/system/Financial/finaSearch"),
        userRoles: [
          "SYS_ADMIN",
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_O",
          "ADMIN_LEVEL_P",
          "ADMIN_LEVEL_Y",
        ],
      },
      {
        path: "/manage/monthCustomer",
        index: "month_customer",
        name: "月结客户",
        component: import(
          "@/components/system/Financial/monthCustomer/index.vue"
        ),
        userRoles: [
          "SYS_ADMIN",
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_O",
          "ADMIN_LEVEL_P",
          "ADMIN_LEVEL_Y",
        ],
      },
      {
        path: "/manage/month_statement",
        index: "month_statement",
        name: "月度报表",
        component: import("@/components/system/Financial/monthStatement"),
        userRoles: [
          "SYS_ADMIN",
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_O",
          "ADMIN_LEVEL_P",
          "ADMIN_LEVEL_Y",
        ],
      },
      {
        path: "/manage/company_reports",
        index: "company_reports",
        name: "顾客报表",
        component: import("@/components/system/Financial/companyReports"),
        userRoles: [
          "SYS_ADMIN",
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_O",
          "ADMIN_LEVEL_P",
          "ADMIN_LEVEL_Y",
        ],
      },
      {
        path: "/manage/invoice",
        index: "invoice",
        name: "发票管理",
        component: import("@/components/system/Financial/invoice"),
        userRoles: [
          "SYS_ADMIN",
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_O",
          "ADMIN_LEVEL_P",
          "ADMIN_LEVEL_Y",
        ],
      },
      {
        path: "/manage/bankSlip",
        index: "bankSlip",
        name: "水单管理",
        component: import("@/components/system/Financial/bankSlip"),
        userRoles: [
          "SYS_ADMIN",
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_O",
          "ADMIN_LEVEL_P",
          "ADMIN_LEVEL_Y",
        ],
      },
      {
        path: "/manage/financial_flow",
        index: "financial_flow",
        name: "流水信息",
        component: import("@/components/system/Financial/flow"),
        userRoles: [
          "SYS_ADMIN",
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_O",
          "ADMIN_LEVEL_P",
          "ADMIN_LEVEL_Y",
        ],
      },
      {
        path: "/manage/financial_company",
        index: "financial_company",
        name: "账户管理",
        component: import("@/components/system/Financial/company"),
        userRoles: [
          "SYS_ADMIN",
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_Y",
          "ADMIN_LEVEL_D",
          "ADMIN_LEVEL_C",
          "ADMIN_LEVEL_B",
          "ADMIN_LEVEL_A",
          "ADMIN_LEVEL_O",
          "ADMIN_LEVEL_P",
        ],
      },
      {
        path: "/manage/group_manage",
        index: "group_manage",
        name: "分组管理",
        component: import("@/components/system/Financial/groupManage"),
        userRoles: [
          "SYS_ADMIN",
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_O",
          "ADMIN_LEVEL_P",
          "ADMIN_LEVEL_Y",
        ],
      },
      {
        path: "/manage/trading_agent",
        index: "trading_agent",
        name: "贸易代理",
        component: import("@/components/system/Financial/tradingAgent"),
        userRoles: [
          "SYS_ADMIN",
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_Y",
          "ADMIN_LEVEL_J",
          "ADMIN_LEVEL_I",
          "ADMIN_LEVEL_A",
          "ADMIN_LEVEL_B",
          "ADMIN_LEVEL_P",
          "ADMIN_LEVEL_O",
        ],
      },
      {
        path: "/manage/sales_commission",
        index: "sales_commission",
        name: "销售提成",
        component: import("@/components/system/Financial/salesCommission"),
        userRoles: [
          "SYS_ADMIN",
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_Y",
          "ADMIN_LEVEL_D",
          "ADMIN_LEVEL_C",
          "ADMIN_LEVEL_P",
          "ADMIN_LEVEL_O",
        ],
        // 'SYS_ADMIN', 'ADMIN_TOP', 'ADMIN_READONLY', 'ADMIN_SALES_MNGR', 'ADMIN_SALES', 'ADMIN_FINANCIAL_MNGR', 'ADMIN_FINANCIAL'
        // userRoles: ['SYS_ADMIN', 'ADMIN_LEVEL_Z', 'ADMIN_LEVEL_Y', 'ADMIN_LEVEL_B', 'ADMIN_LEVEL_A', 'ADMIN_LEVEL_D', 'ADMIN_LEVEL_C']
      },
    ],
  },
  {
    group: "geek",
    groupName: "实时数据",
    icon: "el-icon-coin",
    index: "13",
    menus: [
      {
        path: "/manage/geek",
        index: "geek",
        name: "实时数据查询",
        component: import("@/components/system/geek/index"),
        userRoles: [
          "SYS_ADMIN",
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_Y",
          "ADMIN_LEVEL_J",
          "ADMIN_LEVEL_D",
          "ADMIN_LEVEL_C",
          "ADMIN_LEVEL_B",
          "ADMIN_LEVEL_A",
          "ADMIN_LEVEL_O",
          "ADMIN_LEVEL_P",
        ],
      },
      {
        path: "/manage/geekDetail",
        index: "geekDetail",
        name: "实时数据详情",
        component: import("@/components/system/geek/geekDetail/index.vue"),
        userRoles: [
          "SYS_ADMIN",
          "ADMIN_LEVEL_Z",
          "ADMIN_LEVEL_Y",
          "ADMIN_LEVEL_J",
          "ADMIN_LEVEL_D",
          "ADMIN_LEVEL_C",
          "ADMIN_LEVEL_B",
          "ADMIN_LEVEL_A",
          "ADMIN_LEVEL_O",
          "ADMIN_LEVEL_P",
        ],
      },
    ],
  },
];
// 不需要菜单界面的
export const viewConfig = [
  {
    group: "view",
    groupName: "查看页面",
    icon: "el-icon-trophy",
    index: "19",
    menus: [
      // 在线查看pdf页面
      {
        path: "/view/view_pdf",
        index: "view_pdf",
        name: "查看PDF",
        component: import("@/components/system/viewPage/viewPDF"),
        userRoles: ["all"], // 存在all时不限制
        hidden: true,
      },
    ],
  },
];
