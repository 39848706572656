import Vue from 'vue'
import VueRouter from 'vue-router'
import { MenuConfig, viewConfig, chenfengIframeConfig } from "@/constants/MenuConfig";
import store from "@/store";
import { httpGet } from "@/api/httpUtils";

// // 正式隐藏 贸易代理
// const mode = process.env.VUE_APP_MODE
// if (mode === "development-local" || mode === "development" || mode === "test-build") {
//   console.log("非正式")
// }else{
//   MenuConfig[9].menus.splice(8,1)
//   console.log("非正式")
// }
// // console.log(MenuConfig[9].menus,"MenuConfig")
// console.log(MenuConfig,"MenuConfig")
// // 正式隐藏 贸易代理 End
Vue.use(VueRouter)
console.log('------process.env.VUE_APP_MODE', process.env.VUE_APP_MODE);
const Routers = new VueRouter({
  routes: [
    {
      path: '/',
      component: () =>
        import('@/views/Login'),
      name: 'login',
    },
    {
      path: '/view',
      component: () =>
        import('@/views/view'),
      name: 'view',
      children: viewConfig.flatMap(group => {
        return group.menus.flatMap(menu => {
          return {
            name: menu.index,
            path: menu.path.substring(menu.path.lastIndexOf('/') + 1),
            component: () => menu.component,
          }
        })
      })
    },
    // 提供给合作方的iframe使用的内链pc页面
    {
      path: '/chenfeng/query_history/pc',
      component: () =>
        import('@/components/system/viewPage/chenfeng/pc.vue'),
      name: 'chenfengPc',
    },
    // 提供给合作方的iframe使用的内链mobile页面
    {
      path: '/chenfeng/query_history/mobile',
      component: () =>
        import('@/components/system/viewPage/chenfeng/mobile.vue'),
      name: 'chenfengMobile',
    },
    {
      path: '/manage',
      name: 'manage',
      component: () => import('@/views/Manage'),
      children: MenuConfig.flatMap(group => {
        return group.menus.flatMap(menu => {
          return {
            name: menu.index,
            path: menu.path.substring(menu.path.lastIndexOf('/') + 1),
            component: () => menu.component,
          }
        }).filter(item => !item.hidden);
      })
    },
  ],

})
//!  如需使用测试服务器 数据  需要关闭导航守卫
Routers.beforeEach(async (to, from, next) => {
  //路由前预检
  if (!(store.state.userInfoRole && store.state.userInfo) && !['login', 'chenfengPc', 'chenfengMobile'].includes(to.name)) {
    try {
      console.log('to: ', to)
      console.log('from: ', from)
      let res = await httpGet('/user/admin/role');
      console.log(res,'=======82 role')
      store.commit('setUserInfoRole', res.data)
      res = await httpGet('/user/self');
      store.commit('setUserInfo', res.data)
      next();
    } catch (e) {
      alert('登陆后操作')
      next({
        name: 'login'
      })
    }
  }
  store.commit("menuStore/changePath", to.path);
  next();
})
// 规避一些重复导航的警告（异常警告）
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default Routers
