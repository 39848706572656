export const PART2TABLEHEADER = `
    <td>启运港</td>
    <td>目的港</td>
    <td>承运商</td>
    <td>启运国</td>
    <td>目的国</td>
    `
export const PART2TABLECONTENT = `
    <td>POL</td>
    <td>DOL</td>
    <td>carrier</td>
    <td>COL</td>
    <td>COD</td>
    `
export const PART3TABLEHEADER = `
    <td>启运港</td>
    <td>目的港</td>
    <td>承运商</td>
    <td>启运国</td>
    <td>目的国</td>
    <td>供应商</td>
    <td>中转</td>
    <td>班期</td>
    `
export const PART3TABLECONTENT = `
    <td>POL</td>
    <td>DOL</td>
    <td>carrier</td>
    <td>COL</td>
    <td>COD</td>
    <td>supplier</td>
    <td>transhipment</td>
    <td>ETD</td>
    `