export const toFormData = (object, checkNull = true) => {
    const formData = new FormData()
    Object.keys(object).forEach(key => {
        const v = object[key]
        if(checkNull){
            if (v != null) {
                formData.append(key, object[key])
            }
        }else{
            if (v != null) {
                formData.append(key, object[key])
            }else{
                formData.append(key, "");
            }
        }
        
    })
    return formData
}