export const DISPLAYTYPE = 1
export const CALCULATETYPE = 2
export const PLANTYPES = {
  0: '海运',
  1: '空运',
  2: '铁运',
  3: '国际快递',
}

export const TRANSPACKTYPES = {
  0: '空运',
  1: '海运整箱',
  2: '海运拼箱',
  3: '铁运整箱',
  4: '铁运拼箱',
  5: '国际快递',
}

export const PRICETYPES = {
  2: '第二段数据',
  3: '第三段数据',
  4: '第四段数据',
}