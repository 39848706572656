import axios from 'axios';
import store from '../store/index';
axios.defaults.withCredentials = true

// axios.interceptors.request.use(config=>{
//     console.log(config)
//     console.log('axios request')
//     return config
// },err=>{
//     console.log(err)
// })
// axios.interceptors.response.use(res=>{
//     console.log(res)
//     console.log('axios response')
//     return res
// },err=>{
//     console.log(err)
// })

// TODO: replace this string when in test/production environment

// replace /api_v1 with /react_v1, for security reasons: hide sensitive username and password
// const BASE_URL = "http://127.0.0.1:8090/react_v1";
const checkForbidden = (error, code = 403) => {
    if (error.response && error.response.status === code) {
        // 接口403，登录状态异常时，提示用户重新登录
        store.commit("showReLogin");
    }
}
export const baseUrl = () => {
    //  console.log(process.env);
    const mode = process.env.VUE_APP_MODE
    if (mode === "development" || mode === "test-build") {
        return "https://test-admin.jianyiyun.com/react_v1"
    }
    else if (mode === "development-local") {
        return location.origin.replace("3020", "8090") + "/react_v1"
    }
    return "https://prod-admin.jianyiyun.com/react_v1"

}
let userCookie = {

}
export const httpGet = (apiPath, params) => {
    params = params || {}
    // console.log('http get: ', apiPath, ", params: ", params)
    // return axios.get(baseUrl() + apiPath, {
    //     params: params,
    //     headers: {
    //         'Access-Control-Allow-Origin': '*',
    //         ...userCookie
    //     },
    //     withCredentials: true
    // })
    return new Promise(async (reslove, reject) => {
        try {
            let res = await axios.get(baseUrl() + apiPath, {
                params: params,
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    ...userCookie
                },
                withCredentials: true
            })
            reslove(res);
        } catch (err) {
            reject(err);
            console.error(err);
            checkForbidden(err);
        }
    });
}
export const httpGetCancelable = (apiPath, params, source) => {
    params = params || {}
    // return axios.get(baseUrl() + apiPath, {
    //     params: params,
    //     headers: {
    //         'Access-Control-Allow-Origin': '*',
    //         ...userCookie
    //     },
    //     withCredentials: true,
    //     cancelToken: source.token
    // })

    return new Promise(async (reslove, reject) => {
        try {
            let res = await axios.get(baseUrl() + apiPath, {
                params: params,
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    ...userCookie
                },
                withCredentials: true,
                cancelToken: source.token
            })
            reslove(res);
        } catch (err) {
            reject(err);
            checkForbidden(err);
            console.error(err);
        }
    });
}

export const httpPostRequstBody = (apiPath, bodyData) => {
    // return axios.post(baseUrl() + apiPath, bodyData,
    //     {
    //         headers: {
    //             'Content-Type': 'application/json;charset=UTF-8',
    //             ...userCookie
    //         },
    //     })


    return new Promise(async (reslove, reject) => {
        try {
            let res = await axios.post(baseUrl() + apiPath, bodyData,
                {
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8',
                        ...userCookie
                    },
                })
            reslove(res);
        } catch (err) {
            reject(err);
            checkForbidden(err);
            console.error(err);
        }
    });
}
// export const httpGet_v2 = (apiPath, formData) => {
//     console.log(apiPath, formData)
//     // formData = formData || new FormData()
//     return axios({
//         method: "get",
//         url: baseUrl() + apiPath,
//         params:formData,
//         headers: {
//             // 'Content-Type': 'multipart/form-data',
//             'Access-Control-Allow-Origin': '*',
//                 ...userCookie
//         }
//     })
// }

export const httpPostFormData = (apiPath, formData) => {
    formData = formData || new FormData()
    // return axios.post(baseUrl() + apiPath, formData
    //     ,
    //     {
    //         headers: {
    //             'Access-Control-Allow-Origin': '*',
    //             ...userCookie
    //         },
    //         withCredentials: true
    //     }
    // )
    return new Promise(async (reslove, reject) => {
        try {
            let res = await axios.post(baseUrl() + apiPath, formData
                ,
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        ...userCookie
                    },
                    withCredentials: true
                }
            )
            reslove(res);
        } catch (err) {
            reject(err);
            checkForbidden(err);
            console.error(err);
        }
    });
}

export const httpPostFormDataCancelable = (apiPath, formData, source) => {
    formData = formData || new FormData()
    // return axios.post(baseUrl() + apiPath, formData, {
    //     headers: {
    //         'Access-Control-Allow-Origin': '*',
    //         ...userCookie
    //     },
    //     withCredentials: true,
    //     cancelToken: source.token
    // })
    return new Promise(async (reslove, reject) => {
        try {
            let res = await axios.post(baseUrl() + apiPath, formData, {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    ...userCookie
                },
                withCredentials: true,
                cancelToken: source.token
            })
            reslove(res);
        } catch (err) {
            reject(err);
            checkForbidden(err);
            console.error(err);
        }
    });
}

export const httpDeleate = (apiPath) => {
    // return axios.delete(baseUrl() + apiPath, {
    //     headers: {
    //         'Access-Control-Allow-Origin': '*',
    //         ...userCookie
    //     },
    //     withCredentials: true
    // })
    return new Promise(async (reslove, reject) => {
        try {
            let res = await axios.delete(baseUrl() + apiPath, {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    ...userCookie
                },
                withCredentials: true
            })
            reslove(res);
        } catch (err) {
            reject(err);
            checkForbidden(err);
            console.error(err);
        }
    });
}

export const httpDeleate_v2 = (apiPath, params) => {

    params = params || {}

    // console.log('http get: ', apiPath, ", params: ", params)

    // return axios.delete(baseUrl() + apiPath, {
    //     params: params,
    //     headers: {
    //         'Access-Control-Allow-Origin': '*',
    //         ...userCookie
    //     },
    //     withCredentials: true
    // })
    return new Promise(async (reslove, reject) => {
        try {
            let res = await axios.delete(baseUrl() + apiPath, {
                params: params,
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    ...userCookie
                },
                withCredentials: true
            })
            reslove(res);
        } catch (err) {
            reject(err);
            checkForbidden(err);
            console.error(err);
        }
    });
}

export const httpPost = (apiPath, params) => {
    params = params || {}
    // return axios.post(baseUrl() + apiPath, params, {
    //     headers: {
    //         'Access-Control-Allow-Origin': '*',
    //         ...userCookie
    //     },
    //     withCredentials: true
    // })
    return new Promise(async (reslove, reject) => {
        try {
            let res = await axios.post(baseUrl() + apiPath, params, {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    ...userCookie
                },
                withCredentials: true
            })
            reslove(res);
        } catch (err) {
            reject(err);
            checkForbidden(err);
            console.error(err);
        }
    });
}

// Head
export const httpHead = (apiPath, params) => {

    params = params || {}

    // console.log('http get: ', apiPath, ", params: ", params)
    // return axios.head(baseUrl() + apiPath, {
    //     params: params,
    //     headers: {
    //         'Access-Control-Allow-Origin': '*',
    //         ...userCookie
    //     },
    //     withCredentials: true
    // })
    return new Promise(async (reslove, reject) => {
        try {
            let res = await axios.head(baseUrl() + apiPath, {
                params: params,
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    ...userCookie
                },
                withCredentials: true
            })
            reslove(res);
        } catch (err) {
            reject(err);
            checkForbidden(err);
            console.error(err);
        }
    });
}


