/* 该表为计算的 group 配置表
 * @type 1为展示字段 2为计算字段
 * @part 表示属于第几段配置使用的
 * @transPackType 表示适用于哪一种运输方式
 * @keys 表示该 group 是一个特殊的计算方式，它的计算 key 是单独配置的 
 */
export default {
  // ---------------------- 基本的展示字段 -------------------------//
  currency: {
    type: 1,
    part: [2, 3, 4],
    transPackType: [0, 1, 2, 3, 4, 5],
    zh: '货币',
    en: 'Currency',
  },
  transittime: {
    type: 1,
    part: [ 3 ],
    transPackType: [0, 1, 2, 3, 4, 5],
    zh: '预计航程',
    en: 'Transit Time'
  },
  rvf: {
    type: 1,
    part: [ 3 ],
    transPackType: [0, 1, 2, 3, 4, 5],
    zh: '价格有效期起始日',
    en: 'Rate Valid from'
  },
  rve: {
    type: 1,
    part: [ 3 ],
    transPackType: [0, 1, 2, 3, 4, 5],
    zh: '价格有效期截止日',
    en: 'Rate Valid end'
  },
  routecode: {
    type: 1,
    part: [ 3 ],
    transPackType: [0, 1, 2, 3, 4, 5],
    zh: '航线代码',
    en: 'Route Code'
  },
  weightlimited: {
    type: 1,
    part: [ 3 ],
    transPackType: [0, 1, 2, 3, 4, 5],
    zh: '限重',
    en: 'Weight Limited'
  },
  weightratio: {
    type: 1,
    part: [ 3 ],
    transPackType: [0, 2, 4, 5],
    zh: '泡重比',
    en: 'Weight Ratio'
  },
  doccutof: {
    type: 1,
    part: [ 3 ],
    transPackType: [ 2, 4 ],
    zh: '截单时间',
    en: 'Doc Cut Of'
  },
  cfscutof: {
    type: 1,
    part: [ 3 ],
    transPackType: [2, 4],
    zh: '截货时间',
    en: 'CFS Cut Of'
  },
  loadingwarehouse: {
    type: 1,
    part: [ 3 ],
    transPackType: [ 2 ],
    zh: '装箱仓库',
    en: 'Loading Ware House'
  },
  borderstation: {
    type: 1,
    part: [ 3 ],
    transPackType: [3, 4],
    zh: '出境口岸',
    en: 'Border Station'
  },
  dropoff: {
    type: 1,
    part: [ 3 ],
    transPackType: [3, 4],
    zh: '还箱点',
    en: 'Drop Off'
  },
  starttime: {
    type: 1,
    part: [ 3 ],
    transPackType: [0],
    zh: '空运起飞时间',
    en: 'Air Start Time'
  },
  arrivetime: {
    type: 1,
    part: [ 3 ],
    transPackType: [0],
    zh: '空运到达时间',
    en: 'Air Arrive Time'
  },
  airplane: {
    type: 1,
    part: [ 3 ],
    transPackType: [0],
    zh: '空运飞机类型',
    en: 'Airplane'
  },
  tray: {
    type: 1,
    part: [ 3 ],
    transPackType: [0],
    zh: '是否可接托盘',
    en: 'tray'
  },
  attention: {
    type: 1,
    part: [2, 3, 4],
    transPackType: [0, 1, 2, 3, 4, 5],
    zh: '注意事项',
    en: 'Attention'
  },
  remark: {
    type: 1,
    part: [2, 3, 4],
    transPackType: [0, 1, 2, 3, 4, 5],
    zh: '备注',
    en: 'Remark'
  },

  // ---------------------- 第三段数据配置 -------------------------//
  ofclfee: {
    type: 2,
    part: [ 3 ],
    transPackType: [1],
    zh: '海运整箱费用',
    en: 'OFCL fee'
  },
  olclfee: {
    type: 2,
    part: [ 3 ],
    transPackType: [2],
    zh: '海运拼箱费用',
    en: 'OFCL fee'
  },
  rfclfee: {
    type: 2,
    part: [ 3 ],
    transPackType: [ 3 ],
    zh: '铁运整箱费用',
    en: 'RFCL fee'
  },
  rlclfee: {
    type: 2,
    part: [ 3 ],
    transPackType: [4],
    zh: '铁运拼箱费用',
    en: 'RLCL fee'
  },
  airfee: {
    type: 2,
    part: [ 3 ],
    transPackType: [0],
    keys: [
      { type: 2, key: 'min' },
      { type: 2, key: 0 },
      { type: 2, key: 45 },
      { type: 2, key: 100 },
      { type: 2, key: 300 },
      { type: 2, key: 500 },
      { type: 2, key: 1000 },
    ],
    zh: '空运费用',
    en: 'Air fee'
  },
  transferfee: {
    type: 2,
    part: [ 3 ],
    transPackType: [ 0 ],
    zh: '空运中转费用',
    en: 'Air fee'
  },
  cisf: {
    type: 2,
    part: [ 3 ],
    transPackType: [ 2 ],
    zh: 'CISF',
    en: 'CISF'
  },

  // ---------------------- 第二段跟第四段数据配置 -------------------------//
  // ---------------------- 口岸费用 -------------------------- //
  thc: {
    type: 2,
    part: [2, 4],
    category: 'port',
    transPackType: [0, 1, 2, 3, 4],
    zh: 'THC',
    en: 'THC'
  },
  isps: {
    type: 2,
    part: [2, 4],
    category: 'port',
    transPackType: [0, 1, 2, 3, 4],
    zh: '安保费',
    en: 'ISPS'
  },
  environmentalfee: {
    type: 2,
    part: [2, 4],
    category: 'port',
    transPackType: [0, 1, 2, 3, 4],
    zh: '环保/低硫费',
    en: 'Environmental Fee'
  },
  portdues: {
    type: 2,
    part: [2, 4],
    category: 'port',
    transPackType: [0, 1, 2, 3, 4],
    zh: '港务费',
    en: 'Port Dues'
  },
  porttax: {
    type: 2,
    part: [2, 4],
    category: 'port',
    transPackType: [0, 1, 2, 3, 4],
    zh: '港务税',
    en: 'Port Tax'
  },
  devanning: {
    type: 2,
    part: [2, 4],
    category: 'port',
    transPackType: [0, 1, 2, 3, 4],
    zh: '设备装卸费',
    en: 'Devanning Charge'
  },
  containercleaning: {
    type: 2,
    part: [2, 4],
    category: 'port',
    transPackType: [0, 1, 2, 3, 4],
    zh: '清洗箱费',
    en: 'Container Cleaning Fee'
  },
  containerseal: {
    type: 2,
    part: [2, 4],
    category: 'port',
    transPackType: [0, 1, 2, 3, 4],
    zh: '箱子加封费',
    en: 'Container Seal Fee'
  },
  containermanagement: {
    type: 2,
    part: [2, 4],
    category: 'port',
    transPackType: [0, 1, 2, 3, 4],
    zh: '箱管费',
    en: 'Container Management Fee'
  },
  containerfacliation: {
    type: 2,
    part: [2, 4],
    category: 'port',
    transPackType: [0, 1, 2, 3, 4],
    zh: 'Container Facliation Charges',
    en: 'Container Facliation Charges'
  },
  containermaintenance: {
    type: 2,
    part: [2, 4],
    category: 'port',
    transPackType: [0, 1, 2, 3, 4],
    zh: '修箱费',
    en: 'Container Maintenance Fee'
  },
  carriercurity: {
    type: 2,
    part: [2, 4],
    category: 'port',
    transPackType: [0, 1, 2, 3, 4],
    zh: 'Carrier Security',
    en: 'Carrier Security'
  },
  infrastructure: {
    type: 2,
    part: [2, 4],
    category: 'port',
    transPackType: [0, 1, 2, 3, 4],
    zh: '设备费',
    en: 'Infrastructure Charge'
  },
  ucn: {
    type: 2,
    part: [2, 4],
    category: 'port',
    transPackType: [0, 1, 2, 3, 4],
    zh: 'UCN Fee',
    en: 'UCN Fee'
  },
  portexam: {
    type: 2,
    part: [2, 4],
    category: 'port',
    transPackType: [0, 1, 2, 3, 4],
    zh: '港口安检费',
    en: 'Port Exam Fee'
  },
  dthc: {
    type: 2,
    part: [2, 4],
    category: 'port',
    transPackType: [0, 1, 2, 3, 4],
    zh: 'DTHC Fee',
    en: 'DTHC Fee'
  },
  dcs: {
    type: 2,
    part: [2, 4],
    category: 'port',
    transPackType: [0, 1, 2, 3, 4],
    zh: 'DCS Fee',
    en: 'DCS Fee'
  },
  ins: {
    type: 2,
    part: [2, 4],
    category: 'port',
    transPackType: [0, 1, 2, 3, 4],
    zh: 'INS Fee',
    en: 'INS Fee'
  },
  wsc: {
    type: 2,
    part: [2, 4],
    category: 'port',
    transPackType: [0, 1, 2, 3, 4],
    zh: '冬季附加费',
    en: 'WSC Fee'
  },
  mch: {
    type: 2,
    part: [2, 4],
    category: 'port',
    transPackType: [0, 1, 2, 3, 4],
    zh: 'MCH Fee',
    en: 'MCH Fee'
  },
  insurance: {
    type: 2,
    part: [2, 4],
    category: 'port',
    transPackType: [0, 1, 2, 3, 4],
    zh: '保费',
    en: 'Insurance Fee'
  },
  congestion: {
    type: 2,
    part: [2, 4],
    category: 'port',
    transPackType: [0, 1, 2, 3, 4],
    zh: '拥堵附加费',
    en: 'Port Congestion Surcharge'
  },
  chassis: {
    type: 2,
    part: [2, 4],
    category: 'port',
    transPackType: [0, 1, 2, 3, 4],
    zh: '转运费',
    en: 'Chassis Fee'
  },
  logisticcontainerimpo: {
    type: 2,
    part: [2, 4],
    category: 'port',
    transPackType: [0, 1, 2, 3, 4],
    zh: 'Logistic Container IMPO',
    en: 'Logistic Container IMPO'
  },
  toll: {
    type: 2,
    part: [2, 4],
    category: 'port',
    transPackType: [0, 1, 2, 3, 4],
    zh: '通行费',
    en: 'TOLL Fee'
  },
  freightcertificate: {
    type: 2,
    part: [2, 4],
    category: 'port',
    transPackType: [0, 1, 2, 3, 4],
    zh: 'Freight Certificate',
    en: 'Freight Certificate'
  },
  routingdocumentation: {
    type: 2,
    part: [2, 4],
    category: 'port',
    transPackType: [0, 1, 2, 3, 4],
    zh: 'Routing Documentation',
    en: 'Routing Documentation'
  },
  sim: {
    type: 2,
    part: [2, 4],
    category: 'port',
    transPackType: [0, 1, 2, 3, 4],
    zh: 'SIM',
    en: 'SIM'
  },
  feederdoc: {
    type: 2,
    part: [2, 4],
    category: 'port',
    transPackType: [0, 1, 2, 3, 4],
    zh: 'Feeder Doc Fee',
    en: 'Feeder Doc Fee'
  },
  survey: {
    type: 2,
    part: [2, 4],
    category: 'port',
    transPackType: [0, 1, 2, 3, 4],
    zh: 'SURVEY Charges',
    en: 'SURVEY Charges'
  },
  muc: {
    type: 2,
    part: [2, 4],
    category: 'port',
    transPackType: [0, 1, 2, 3, 4],
    zh: 'MUC Charges',
    en: 'MUC Charges'
  },
  shippingline: {
    type: 2,
    part: [2, 4],
    category: 'port',
    transPackType: [0, 1, 2, 3, 4],
    zh: '船东杂费',
    en: 'Shipping Line Charges'
  },
  emi: {
    type: 2,
    part: [2, 4],
    category: 'port',
    transPackType: [0, 1, 2, 3, 4],
    zh: 'EMI',
    en: 'EMI'
  },
  containerdeposit: {
    type: 2,
    part: [2, 4],
    category: 'port',
    transPackType: [0, 1, 2, 3, 4],
    zh: '放箱押金',
    en: 'Container Deposit Fee'
  },
  containerimblance: {
    type: 2,
    part: [2, 4],
    category: 'port',
    transPackType: [0, 1, 2, 3, 4],
    zh: 'Container Imblance',
    en: 'Container Imblance'
  },
  booking: {
    type: 2,
    part: [2, 4],
    category: 'port',
    transPackType: [0, 1, 2, 3, 4],
    zh: '订舱费',
    en: 'Booking Fee'
  },
  vgm: {
    type: 2,
    part: [2, 4],
    category: 'port',
    transPackType: [0, 1, 2, 3, 4],
    zh: '称重费',
    en: 'VERIFIED GROSS MASS'
  },


  // ---------------------- 文件及服务费用 -------------------------- //
  blfee: {
    type: 2,
    part: [2, 4],
    category: 'file',
    transPackType: [0, 1, 2, 3, 4],
    zh: '提单费',
    en: 'B/L Fee'
  },
  handling: {
    type: 2,
    part: [2, 4],
    category: 'file',
    transPackType: [0, 1, 2, 3, 4],
    zh: '操作费',
    en: 'Handling Fee'
  },
  deliveryorder: {
    type: 2,
    part: [2, 4],
    category: 'file',
    transPackType: [0, 1, 2, 3, 4],
    zh: '换单费',
    en: 'Delivery Order Fee'
  },
  release: {
    type: 2,
    part: [2, 4],
    category: 'file',
    transPackType: [0, 1, 2, 3, 4],
    zh: '电放费',
    en: 'Release fee'
  },
  documentation: {
    type: 2,
    part: [2, 4],
    category: 'file',
    transPackType: [0, 1, 2, 3, 4],
    zh: '文件费',
    en: 'Documentation fee'
  },
  transitdocument: {
    type: 2,
    part: [2, 4],
    category: 'file',
    transPackType: [0, 1, 2, 3, 4],
    zh: '单据传递费',
    en: 'Transit document'
  },
  agency: {
    type: 2,
    part: [2, 4],
    category: 'file',
    transPackType: [0, 1, 2, 3, 4],
    zh: '代理费',
    en: 'Agency Fee'
  },
  service: {
    type: 2,
    part: [2, 4],
    category: 'file',
    transPackType: [0, 1, 2, 3, 4],
    zh: '代理费',
    en: 'Agency Fee'
  },
  services: {
    type: 2,
    part: [2, 4],
    category: 'file',
    transPackType: [0, 1, 2, 3, 4],
    zh: '服务费',
    en: 'Services Charges'
  },

  // ---------------------- 清关及舱单费用 -------------------------- //
  ensorams: {
    type: 2,
    part: [2, 4],
    category: 'customs',
    transPackType: [0, 1, 2, 3, 4],
    zh: '预报/舱单费',
    en: 'ENS/AMS'
  },
  customsclearance: {
    type: 2,
    part: [2, 4],
    category: 'customs',
    transPackType: [0, 1, 2, 3, 4],
    zh: '报关费',
    en: 'Customs Clearance'
  },
  edi: {
    type: 2,
    part: [2, 4],
    category: 'customs',
    transPackType: [0, 1, 2, 3, 4],
    zh: 'EDI数据费',
    en: 'EDI Fee'
  },
  billofladingsurrender: {
    type: 2,
    part: [2, 4],
    category: 'customs',
    transPackType: [0, 1, 2, 3, 4],
    zh: '货权转移',
    en: 'Bill of Lading Surrender Fee'
  },
  pickupcharge: {
    type: 2,
    part: [2, 4],
    category: 'customs',
    transPackType: [0, 1, 2, 3, 4],
    zh: 'Pick up charge base on city limit',
    en: 'Pick up charge base on city limit'
  },
  isffiling: {
    type: 2,
    part: [2, 4],
    category: 'customs',
    transPackType: [0, 1, 2, 3, 4],
    zh: 'ISF备案费',
    en: 'ISF Filing'
  },
  additionalcommercialinvoice: {
    type: 2,
    part: [2, 4],
    category: 'customs',
    transPackType: [0, 1, 2, 3, 4],
    zh: '打发票费',
    en: 'Additional Commercial Invoice'
  },
  tluc: {
    type: 2,
    part: [2, 4],
    category: 'customs',
    transPackType: [0, 1, 2, 3, 4],
    zh: 'TLUC',
    en: 'TLUC'
  },
  etoken: {
    type: 2,
    part: [2, 4],
    category: 'customs',
    transPackType: [0, 1, 2, 3, 4],
    zh: 'E token',
    en: 'E token'
  },
  quarantine: {
    type: 2,
    part: [2, 4],
    category: 'customs',
    transPackType: [0, 1, 2, 3, 4],
    zh: '商检费',
    en: 'Quarantine Verification fee(AQIS)'
  },
  cmrcompliance: {
    type: 2,
    part: [2, 4],
    category: 'customs',
    transPackType: [0, 1, 2, 3, 4],
    zh: 'CMR查验费',
    en: 'CMR Compliance fee(AQIS)'
  },

  // ---------------------- 其他费用 -------------------------- //
  customsexamination: {
    type: 2,
    part: [2, 4],
    category: 'other',
    transPackType: [0, 1, 2, 3, 4],
    zh: '查验费',
    en: 'Customs examination'
  },
  wine: {
    type: 2,
    part: [2, 4],
    category: 'other',
    transPackType: [0, 1, 2, 3, 4],
    zh: '酒类报关单据费',
    en: 'Clearance of documents accompanying wine'
  },
  owc: {
    type: 2,
    part: [2, 4],
    category: 'other',
    transPackType: [0, 1, 2, 3, 4],
    zh: '超重附加费',
    en: 'Overweight Charge'
  },
  t1: {
    type: 2,
    part: [2, 4],
    category: 'other',
    transPackType: [0, 1, 2, 3, 4],
    zh: '欧盟过境',
    en: 'T1'
  },
  fhd: {
    type: 2,
    part: [2, 4],
    category: 'other',
    transPackType: [0, 1, 2, 3, 4],
    zh: 'FHD(For DDP/DDU only)',
    en: 'FHD(For DDP/DDU only)'
  },
  operative: {
    type: 2,
    part: [2, 4],
    category: 'other',
    transPackType: [0, 1, 2, 3, 4],
    zh: '作业费',
    en: 'Operative Charges'
  },
  reprint: {
    type: 2,
    part: [2, 4],
    category: 'other',
    transPackType: [0, 1, 2, 3, 4],
    zh: '重制提单费',
    en: 'Reprint BL'
  },
  cycharge: {
    type: 2,
    part: [2, 4],
    category: 'other',
    transPackType: [0, 1, 2, 3, 4],
    zh: 'CY Charge',
    en: 'CY Charge'
  },
}