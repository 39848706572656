export const USERLISTCOLUMNS = [
  {
    title: '用户ID',
    key: 'id',
    width: 90,
  },
  {
    title: '姓名',
    key: 'name',
    width: 100,
  },
  {
    title: '手机号',
    key: 'phone',
    width: 140,
  },
  {
    title: '邮箱',
    key: 'email',
    width: 200,
  },
  {
    title: '公司',
    key: 'company'
  },
  {
    title: '创建时间',
    key: 'createTime',
    width: 200,
  },
  {
    title: '更新时间',
    key: 'updateTime',
    width: 200,
  },
  {
    title: '操作',
    slot: 'action',
    width: 80,
  },
]