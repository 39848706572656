/*
 * 第二段跟第四段费用种类
 */
export default {
  'inland': {
    zh: '内陆运输费'
  },
  'port': {
    zh: '港口费用'
  },
  'file': {
    zh: '文件及服务费用'
  },
  'customs': {
    zh: '清关及舱单费用'
  },
  'other': {
    zh: '其他费用'
  }
}