import axios from 'axios'
import moment from 'moment'
import CODE from '@/constants/code'
import {
  USERLISTCOLUMNS
} from '@/constants/columns'
import CONFIGCATEGORYS from '@/constants/configCategorys'
import CONFIGGROUPS from '@/constants/configGroups'
import CONFIGKEYS from '@/constants/configKeys'
import {
  PLANTYPES,
  PRICETYPES,
  DISPLAYTYPE,
  CALCULATETYPE,
  TRANSPACKTYPES,
} from '@/constants/interface'
import {
  PART2TABLEHEADER,
  PART2TABLECONTENT,
  PART3TABLEHEADER,
  PART3TABLECONTENT,
} from '@/constants/configTable'

export default {
  install: Vue => {
    Vue.mixin({
      data () {
        return {
          CODE,
          DISPLAYTYPE,
          CALCULATETYPE,
          CONFIGKEYS,
          CONFIGGROUPS,
          CONFIGCATEGORYS,
          PLANTYPES,
          PRICETYPES,
          TRANSPACKTYPES,
          USERLISTCOLUMNS,
          PART2TABLEHEADER,
          PART2TABLECONTENT,
          PART3TABLEHEADER,
          PART3TABLECONTENT,
        }
      },
      methods: {
        // getData (url, params, resolve) {
        //   axios({
        //     method: 'POST',
        //     baseURL: 'http://101.132.149.31',
        //     url: url,
        //     headers: {
        //       'adminToken': typeof (localStorage.getItem('adminToken')) === 'string' ? localStorage.getItem('adminToken') : null,
        //     },
        //     timeout: 30000,
        //     data: params,
        //   }).then(res => {
        //     if (resolve) {
        //       resolve(res.data);
        //     }
        //   })
        // },

        // getData (url, params, resolve) {
        //   axios({
        //     method: 'POST',
        //     baseURL: 'http://127.0.0.1:8090/api_v1',
        //     url: url,
        //     headers: {
        //       'Authorization': 'Basic ' + btoa('sysadmin_01:UnqZo5Zfsn1PT8l6'),
        //       'Access-Control-Allow-Origin': '*', "Content-Type": "multipart/form-data"
        //     },
        //     timeout: 30000,
        //     data: params,
        //   }).then(res => {
        //     if (resolve) {
        //       resolve(res.data);
        //     }
        //   })
        // },

        $formatDate(value, type = 'YYYY-MM-DD HH:mm:ss') {
          return moment(value).format(type)
        },
        $openWindow(link, new_target = true) {
          const obj = document.createElement('a')
          if (new_target) {
            obj.target = '_blank'
          }
          obj.referrerPolicy = 'origin'
          obj.href = link
          obj.click()
        }
      },
      computed: {
        
      },
    })
  }
}